// import "@babel/polyfill";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import loadable from '@loadable/component'

class Loading extends React.Component {
    render() {
        return (
            <div class="preloader">
                <div class="spinner"/>
            </div>
        );
    }
}

const App = loadable(() => import("./App"));

window.onload = ()=> {
    ReactDOM.render(
    <App/>,
    document.getElementById("root")
);}

// NOTE: URL MANIPULATION

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
